// import React fr
import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Row, Col } from "react-bootstrap"

class CustomNavbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    return(
      <Row noGutters className="custom-navbar-row">
        <Col md={6}>
          <h3 className="d-inline-block custom-navbar-home">{this.props.siteInfo.website_name.text}</h3>
        </Col>
        <Col md={6}>
          {this.props.menu.map((menu) =>
            <h3 className="d-inline-block float-right custom-navbar-menu">{menu.data.menu_title}</h3>
          )}
        </Col>
      </Row>
    )
  }
}


export default CustomNavbar
