// import React fr
import React from "react"
import {Link} from "gatsby"
import { Row, Col } from "react-bootstrap"

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }
  render(){
    return(
      <Row noGutters className="footer-row">
        <Col md={6} className="d-inline-block">
          <p>Arif Khoirudin</p>
        </Col>
        <Col md={6} className="d-inline-block">
          <p>2022</p>
        </Col>
      </Row>
    )
  }
}
export default Footer
